import React, { useState, useEffect } from "react";
import { useNavigate, NavLink, Link } from "react-router-dom";
import axios from "axios";

function Sidebar() {
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const subAdminlist = async () => {
    await axios
      .post(
        `/v1/super-Agent/get-roles`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.data;
        setRoleData(data);
        console.log("Roles--------->>>>>>>", data);
        // console.log('@@@@@',data.customer.addCustomer)
      });
  };

  useEffect(() => {
    subAdminlist();
  }, []);
  const handleClick = () => {
    setIsClicked(!isClicked);
  };
  let roles = localStorage.getItem("superAgentRoles");
  let token = localStorage.getItem("SuperAgentChromepayToken");

  let orgType = localStorage.getItem("orgType");
  //  console.log(token);
  console.log(orgType);



  return (
    <>
      <div
        id="kt_aside"
        className="aside bg-primary "
        data-kt-drawer="true"
        data-kt-drawer-name="aside"
        data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="auto"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_aside_toggle"
      >
        <div
          className="aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-8"
          id="kt_aside_logo"
        >
          <NavLink to="/Super-agent-dashbord">
            <img
              alt="Logo"
              src="/assets_new/images/logo.png"
              className="h-50px"
            />
          </NavLink>
        </div>

        <div
          className="aside-nav d-flex flex-column align-lg-center flex-column-fluid w-100 pt-5 pt-lg-0"
          id="kt_aside_nav"
        >
          <div
            className="hover-scroll-overlay-y my-2 my-lg-5"
            id="kt_aside_menu_wrapper"
            data-kt-scroll="true"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
            data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu"
            data-kt-scroll-offset="5px"
          >
            <div
              id="kt_aside_menu"
              className="menu menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-6"
              data-kt-menu="true"
            >
              <div className="menu-item here show py-2">
                <NavLink
                  to="/Super-agent-dashbord"
                  className="menu-link menu-center"
                >
                  <span className="menu-icon me-0">
                    <i className="fad fa-home-lg fs-1"></i>
                  </span>
                </NavLink>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <div className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i className="far fa-user-plus fs-1"></i>
                  </span>
                </div>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-250px showlist">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        CRM
                      </span>
                    </div>
                  </div>
                  {orgType === "Union" ? (
                    <>
                      <div className="menu-item menu-accordion">
                        <NavLink
                          to="/Super-agent/customer-list"
                          className="menu-link"
                        >
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span>
                          <span className="menu-title">Active Bruh IDs</span>
                        </NavLink>
                        <NavLink
                          to="/customer/addCustomer"
                          className="menu-link"
                        >
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span>
                          <span className="menu-title">Create Digital ID</span>
                        </NavLink>
                        <NavLink
                          to="/Super-agent/customer-awating"
                          className="menu-link"
                        >
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span>

                          <span className="menu-title">
                            Bruh IDs Awaiting Approval
                          </span>
                        </NavLink>
                        <NavLink
                          to="/Super-agent/customer-block"
                          className="menu-link"
                        >
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span>
                          <span className="menu-title">Blocked Bruh IDs </span>
                        </NavLink>
                        <NavLink
                          to="/super/dash-customers"
                          className="menu-link"
                        >
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span>
                          <span className="menu-title">Dashboard Bruh IDs</span>
                        </NavLink>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="menu-item menu-accordion">
                        <NavLink
                          to="/Super-agent/customer-list"
                          className="menu-link"
                        >
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span>
                          <span className="menu-title">Active Union IDs</span>
                        </NavLink>
                        {/* <NavLink to="/Super-agent-Customer" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Create Digital ID</span>
                    </NavLink> */}
                        <NavLink
                          to="/Super-agent/customer-awating"
                          className="menu-link"
                        >
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span>

                          <span className="menu-title">
                            Union-IDs Awaiting Approval
                          </span>
                        </NavLink>
                        <NavLink
                          to="/Super-agent/customer-block"
                          className="menu-link"
                        >
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span>
                          <span className="menu-title">Blocked Union-IDs </span>
                        </NavLink>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                 2
                    <i class="fas fa-badge-dollar fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist1">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Wallet Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <NavLink to="/Super-agent/wallet-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Wallet List</span>
                    </NavLink>
                  </div>
                </div>
              </div> */}
              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i class="fas fa-users-medical fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Bank Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <NavLink to="/Agent-Add-Account-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Account List</span>
                    </NavLink>
                  </div>
                </div>
              </div> */}
              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i class="fas fa-users-slash fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Commission
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <NavLink to="/Agent-comssion-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">
                        Customer Commission List
                      </span>
                    </NavLink>
                  </div>
                </div>
              </div> */}

              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">

                    <i class="fad fa-sack-dollar fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist5">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                      Customer Loans Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion"> */}

              {/* <NavLink to="/SuperAgentloanApply" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Loan Apply List</span>
                    </NavLink> */}

              {/* <NavLink to="/Super-agent/loan-pass" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Loan Pass List</span>
                    </NavLink> */}
              {/* 
                    <NavLink to="/Super-Agent-pendingloan" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Loan  Pending List</span>
                    </NavLink> */}

              {/* <NavLink to="/superagent/loan-defulter/level-one" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Loan  Defaulter List</span>
                    </NavLink>
                  </div>
                </div>
              </div> */}

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    {/* <i class="fas fa-users-slash fs-1"></i> */}
                    <i class="fas fa-users-class fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Agent Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link to="/add-agent-by-super" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Add Agent</span>
                    </Link>
                    <Link to="/superagent-agent-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Agent List</span>
                    </Link>
                    <Link
                      to="/superagent/super-agent-block"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Blocked Agents</span>
                    </Link>
                  </div>
                </div>
              </div>

              {roleData.some((item) => item.type === "loan") && (
                <div
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                  data-kt-menu-placement="right-start"
                  className="menu-item py-2 icon"
                >
                  <span className="menu-link menu-center">
                    <span className="menu-icon me-0">
                      {/* <i class="fas fa-users-slash fs-1"></i> */}
                      {/* <i class="fas fa-users-class fs-1"></i> */}
                      <i className="fas fa-university fs-1"></i>
                    </span>
                  </span>

                  <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                    <div className="menu-item">
                      <div className="menu-content text-start">
                        <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                          Loan Management
                        </span>
                      </div>
                    </div>

                    <div className="menu-item menu-accordion">
                      {/* <Link to="/Super-Agent-pendingloan" className="menu-link"> */}
                      <Link
                        to="/superagent/Loan-Apply-customer-List"
                        className="menu-link"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">
                          Pending Loan Applications{" "}
                        </span>
                      </Link>
                      <Link to="/Super-Agent-pendingloan" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Approved Loans </span>
                      </Link>
                      {/* <Link to="/Super-Agent-Share-Apply-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Approved Share </span>


                    </Link> */}

                      <Link
                        to="/superagent/Loan-Reject-customer-List"
                        className="menu-link"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Rejected Loans </span>
                      </Link>

                      <Link
                        to="/Super-agent/Emi-logs-list"
                        className="menu-link"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title"> EMI Logs </span>
                      </Link>
                    </div>
                  </div>
                </div>
              )}





              {roleData.some((item) => item.type === "saving") && (
                <div
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                  data-kt-menu-placement="right-start"
                  className="menu-item py-2 icon"
                >
                  <span className="menu-link menu-center">
                    <span className="menu-icon me-0">
                      {/* <i class="fas fa-users-slash fs-1"></i> */}
                      {/* <i class="fas fa-users-class fs-1"></i> */}
                      <i class="fas fa-piggy-bank fs-1"></i>
                    </span>
                  </span>

                  <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                    <div className="menu-item">
                      <div className="menu-content text-start">
                        <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                          Saving Management
                        </span>
                      </div>
                    </div>

                    <div className="menu-item menu-accordion">
                      {/* <Link to="/Super-Agent-pendingloan" className="menu-link"> */}
                      <Link to="/super/saving/mandatory" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Mandatory Savings</span>
                      </Link>
                      <Link to="/super/saving/voluntory" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Voluntary Savings </span>
                      </Link>

                      <Link
                        to="/super/saving/Intrest/transactions"
                        className="menu-link"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">
                          Monthly Interest Transactions
                        </span>
                      </Link>

                      <Link
                        to="/Super-agent/Emi-logs-list"
                        className="menu-link"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title"> EMI Logs </span>
                      </Link>
                      <Link
                        to="/super/withdrawal/request"
                        className="menu-link"
                      >
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Withdrawal Request</span>
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              {roleData.some((item) => item.type === "buyingAndSelling") && (
                <div
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                  data-kt-menu-placement="right-start"
                  className="menu-item py-2 icon"
                >
                  <span className="menu-link menu-center">
                    <span className="menu-icon me-0">
                      <i className="fab fa-sellcast fs-1"></i>
                    </span>
                  </span>

                  <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                    <div className="menu-item">
                      <div className="menu-content text-start">
                        <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                          Store Front Management
                        </span>
                      </div>
                    </div>

                    <div className="menu-item menu-accordion">
                      <Link to="/products/ManagementList" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Product Management</span>
                      </Link>
                      <Link to="/user-order/list" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">User Orders</span>
                      </Link>
                      {/* <Link to="/sell-order/list" className="menu-link">
                    <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">User Selling Orders</span>
                </Link> */}
                      <Link to="/super/user/transaction" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">User Transaction</span>
                      </Link>
                      <Link to="/Buy/Products" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Buy Product</span>
                      </Link>
                      <Link to="/buying/product/list" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Buying Transactions</span>
                      </Link>
                      <Link to="/non/member/register" className="menu-link">
                        <span className="menu-bullet">
                          <span className="bullet bullet-dot"></span>
                        </span>
                        <span className="menu-title">Non-Member Store</span>
                      </Link>
                    </div>
                  </div>
                </div>
              )}

            

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    {/* <i class="fas fa-users-slash fs-1"></i> */}
                    {/* <i class="fas fa-users-class fs-1"></i> */}
                    <i class="fas fa-users fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Organisation Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link
                      to="/superagent/add-admin-by-super"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Add Admin</span>
                    </Link>
                    <Link
                      to="/superagent/view-admin-list"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">View Admin</span>
                    </Link>
                    <Link
                      to="/superagent/admin-block-list"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Blocked Admin</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i
                      class="fas fa-file-chart-line"
                      style={{ fontSize: "1.8rem" }}
                    ></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Reports
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <Link
                      to="superagent/agent-report-agent"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Agent Report</span>
                    </Link>

                    <Link
                      to="/superagent/super-agent-report"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Super Agent Report</span>
                    </Link>
                    <Link
                      to="/superagent/transaction/reports"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">
                        Super Agent Transaction Reports
                      </span>
                    </Link>
                    {/* <Link to="/superagent/super-agent-block" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title"> Blocked Agents</span>
                      
                      
                    </Link> */}
                  </div>
                </div>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i class="fad fa-download fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist4">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Export Files
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    {orgType === "Union" ? (
                      <>
                        <NavLink
                          to="/superagent/super-agent-active-export"
                          className="menu-link"
                        >
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span>
                          <span className="menu-title">Active Bruh IDs</span>
                        </NavLink>
                      </>
                    ) : (
                      <>
                        <NavLink
                          to="/superagent/super-agent-active-export"
                          className="menu-link"
                        >
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                          </span>
                          <span className="menu-title">Active Union IDs</span>
                        </NavLink>
                      </>
                    )}

                    <NavLink
                      to="/superagent/super-agent-export-agent"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Agent List</span>
                    </NavLink>
                    {/* <NavLink
                      to="/superagent/super-agent-export-admin"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Admin List</span>
                    </NavLink> */}
                  </div>
                </div>
              </div>

              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i class="fas fa-landmark fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist4">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Loan Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <NavLink
                      to="/superagent/Myloan/Myapplyloanlist"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">My Loan</span>
                    </NavLink>
                  </div>
                </div>
              </div>


              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                 
                    <i class="far fa-poll fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist4">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                        Share Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                    <NavLink
                      to="/superagent/Add-requiredbyOrg"
                      className="menu-link"
                    >
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">My Share</span>
                    </NavLink>
                  </div>
                </div>
              </div>


              {/* 
              <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i className="fas fa-hand-holding-usd fa-lg"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                     My Loan 
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                  <Link to="/superagent/Myloan/Myapplyloanlist" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Loan list</span>
                    </Link>
                  
                 
                  </div>
                </div>
              </div> */}

              {/* <div
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-placement="right-start"
                className="menu-item py-2 icon"
              >
                <span className="menu-link menu-center">
                  <span className="menu-icon me-0">
                    <i class="fas fa-users-slash fs-1"></i>
                  </span>
                </span>

                <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist3">
                  <div className="menu-item">
                    <div className="menu-content text-start">
                      <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                      Savings Management
                      </span>
                    </div>
                  </div>

                  <div className="menu-item menu-accordion">
                  <Link to="/superagent/saving-list" className="menu-link">
                      <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                      </span>
                      <span className="menu-title">Savings Account List</span>
                    </Link>
                    
                   
                
                 
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div
          className="aside-footer d-flex flex-column align-items-center flex-column-auto icon"
          id="kt_aside_footer"
        >
          {/* <div className="mb-7">
            <button
              type="button"
              className="btn btm-sm btn-custom btn-icon icon "
              data-kt-menu-trigger="click"
              data-kt-menu-overflow="true"
              data-kt-menu-placement="top-start"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-dismiss="click"
              title="Profile Setting"
            >
              <i className="fonticon-setting fs-1"></i>
            </button>

            <div
              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px showlist5"
              data-kt-menu="true"
            >
              <div className="menu-item px-3">
                <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">
                  Profile Setting
                </div>
              </div>

              <div className="separator mb-3 opacity-75"></div>

              <div className="menu-item px-3">
                <NavLink to="/agent-my-profile" className="menu-link px-3">
                  My Profile
                </NavLink>
              </div>

              <div className="menu-item px-3">
                <NavLink to="/Agent-change-password" className="menu-link px-3">
                  Account Settings
                </NavLink>
              </div>

              <div className="menu-item px-3"></div>

              <div className="separator mb-3 opacity-75"></div>
            </div>
          </div> */}

          <div
            className="aside-footer d-flex flex-column align-items-center flex-column-auto"
            id="kt_aside_footer"
          >
            <div className="mb-7">
              <button
                type="button"
                className="btn btm-sm btn-custom btn-icon icon"
                onClick={handleClick}
              >
                <i className="fonticon-setting fs-1"></i>
              </button>

              <div
                className={
                  isClicked
                    ? "dropdown-profile menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px position-fixed showlist6 show"
                    : "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px  dropdown-profile  position-fixed showlist6"
                }
                data-kt-menu="true"
              >
                <div className="menu-item px-3">
                  <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">
                    Profile Setting
                  </div>
                </div>

                <div className="separator mb-3 opacity-75"></div>

                <div className="menu-item menu-accordion">
                  <NavLink
                    onClick={handleClick}
                    to="/superagent/profile"
                    className="menu-link "
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title"> My Profile</span>
                  </NavLink>
                  <NavLink
                    onClick={handleClick}
                    to="/loan-request"
                    className="menu-link"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Request For Loan</span>
                  </NavLink>
                  <NavLink
                    onClick={handleClick}
                    to="/super/Change-intrest-rate"
                    className="menu-link"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Change Interest Rate</span>
                  </NavLink>
                  <NavLink
                    onClick={handleClick}
                    to="/super/saving/price"
                    className="menu-link"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Saving Setting</span>
                  </NavLink>
                  <NavLink
                    onClick={handleClick}
                    to="/super/share/price"
                    className="menu-link"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Share Setting</span>
                  </NavLink>
                  <NavLink
                    onClick={handleClick}
                    to="/super/Loan/Type/Setting"
                    className="menu-link"
                  >
                    <span className="menu-bullet">
                      <span className="bullet bullet-dot"></span>
                    </span>
                    <span className="menu-title">Loan Setting</span>
                  </NavLink>
                </div>

                <div className="separator mb-3 opacity-75"></div>
              </div>
            </div>
          </div>

          {/* <div
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-placement="right-start"
            className="menu-item py-2  mb-5"
          >
            <span className="menu-link menu-center">
              <span className="menu-icon me-0">
               
                <i className="fonticon-setting fs-1"></i>
              </span>
            </span>

            <div className="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-200px w-lg-225px showlist6">
              <div className="menu-item">
                <div className="menu-content text-start">
                  <span className="menu-section fs-5 fw-bolder ps-1 py-1">
                    Profile Setting
                  </span>
                </div>
              </div>

              <div className="menu-item menu-accordion">
                <NavLink to="/agent-my-profile" className="menu-link ">
                  <span className="menu-bullet">
                    <span className="bullet bullet-dot"></span>
                  </span>
                  <span className="menu-title"> My Profile</span>
                </NavLink>
                <NavLink to="/Agent-change-password" className="menu-link">
                  <span className="menu-bullet">
                    <span className="bullet bullet-dot"></span>
                  </span>
                  <span className="menu-title">  Account Settings</span>
                </NavLink>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
export default Sidebar;